.feedback-dialog-container {
  width: 346px;
  height: 480px;
  position: absolute;
  left: calc(50% - 173px);
  top: calc(50% - 240px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.feedback-file-box {
  display: inline-block;
  height: 30px;
  margin-left: 35px;
  cursor: pointer;
  font-size: 15px;
}
.feedback-file-box::placeholder {
  color: #999;
  font-style: italic;
}
.feedback-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
}
#feedback-dialog-content-box {
  height: 110px;
}
.feedback-dialog-info-text {
  width: 90%;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.25;
  opacity: 1;
  text-align: center;
  margin-top: 10px;
  margin-left: 5%;
  margin-bottom: 10px;
}

.feedback-dialog-link-text {
  width: 94px;
  height: 29px;
  opacity: 1;
  position: absolute;
  left: 181px;
  top: 100px;
  font-size: 13px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.feedback-dialog-content-box {
  width: calc(100% - 50px);
  margin-left: 25px;
  height: 50px;
  opacity: 1;
  font-size: 13px;
  line-height: 14px;
  background-color: rgba(75, 75, 75, 0);
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 5px;
}
