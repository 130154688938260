.book-list-item {
  width: 133px;
  /* height: 192px; */
  float: left;
  overflow: hidden;
  position: relative;
}
.book-item-cover {
  width: 105px;
  /* height: 137px; */
  opacity: 1;
  margin: 10px 15px 5px 15px;
  cursor: pointer;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;

  border-radius: 2px;
}
.book-item-image {
  border-radius: 2px;
}
.book-item-title {
  width: 80%;
  margin-left: 10%;
  /* margin-bottom: 5px; */
  height: 31px;
  font-size: 12px;
  line-height: 15px;
  opacity: 1;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  position: relative;
}
.book-selected-icon {
  opacity: 0.5;
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.book-more-action {
  /* position: absolute;
  bottom: 23px;
  right: 17px; */
  transform: rotate(90deg);
  float: right;
  font-size: 14px;
  cursor: pointer;
  margin-right: 14px;
  margin-top: 4px;
  opacity: 0.8;
}
.book-heart-action {
  /* position: absolute;
  bottom: 23px;
  right: 17px; */
  float: right;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 6px;
  margin-top: 4px;
  opacity: 0.8;
  color: rgb(231, 69, 69);
}
.reading-progress-icon {
  /* position: absolute;
  bottom: 18px;
  left: 17px; */
  /* width: 31px;
  height: 31px; */
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
  font-size: 12px;
  /* line-height: 31px; */
  cursor: pointer;
}
@keyframes slide-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-down-rotate {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.reading-percentage-char {
  display: inline-block;
  transform: scale(0.5) translate(-5px, 2px);
}
.book-love-icon {
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
}
.book-loved-icon {
  position: absolute;
  bottom: 23px;
  right: 19px;
  font-size: 20px;
  color: #f87356;
  cursor: pointer;
  z-index: 2;
}
.book-selected-icon {
  position: absolute;
  bottom: 60px;
  right: 21px;
  font-size: 20px;
  color: #f87356;
  cursor: pointer;
  z-index: 2;
}

.action-dialog-parent {
  position: fixed;
  top: 0px;
  left: 200px;
  z-index: 10;
}
