.add-dialog-container {
  width: 309px;
  height: 189px;
  opacity: 1;
  position: absolute;
  left: calc(50vw - 155px);
  top: calc(50vh - 95px);
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.add-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 22px;
  font-weight: 500;
}
.add-dialog-shelf-list-container {
  width: 100%;
  font-size: 15px;
  line-height: 14px;
  opacity: 1;
  position: absolute;
  top: 55px;
}
.add-dialog-shelf-list-text {
  width: 50px;
  float: left;
  margin-left: 25px;
  margin-top: 7px;
}
.add-dialog-shelf-list-box {
  float: left;
  width: 210px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 31px;
  border-radius: 5px;
}
.add-dialog-shelf-list-option {
  width: 169px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  margin-left: 16px;
  font-size: 15px;
  line-height: 31px;
  cursor: pointer;
  text-align: left;
}
.add-dialog-new-shelf-container {
  width: 100%;
  font-size: 15px;
  line-height: 14px;
  opacity: 1;
  position: absolute;
  top: 95px;
}
.add-dialog-new-shelf-text {
  width: 50px;
  float: left;
  margin-left: 25px;
  margin-top: 7px;
}
.add-dialog-new-shelf-box {
  float: left;
  width: 210px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  padding-left: 5px;
  font-size: 15px;
  line-height: 31px;
  border-radius: 5px;
}
.add-dialog-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  position: absolute;
  right: 25px;
  bottom: 15px;
}
.add-dialog-cancel {
  min-width: 60px;
  height: 26px;
  opacity: 1;
  /* position: absolute;
  left: 147px;
  top: 144px; */
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  margin-right: 10px;
  line-height: 26px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
.add-dialog-confirm {
  min-width: 64px;
  height: 30px;
  opacity: 1;

  font-size: 13px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 30px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
