.token-dialog-container {
  width: 454px;
  height: 278px;
  position: absolute;
  left: calc(50% - 227px);
  top: calc(50% - 139px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}

.token-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
}

.token-dialog-info-text {
  width: 90%;
  height: 46px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  opacity: 1;
  text-align: center;
  overflow-y: scroll;
  margin-top: 10px;
  margin-left: 5%;
}

.token-dialog-link-text {
  width: 94px;
  height: 29px;
  opacity: 1;
  position: absolute;
  left: 181px;
  top: 100px;
  font-size: 13px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.token-dialog-token-box {
  width: calc(100% - 50px);
  margin-left: 25px;
  height: 70px;
  opacity: 1;
  font-size: 13px;
  line-height: 14px;
  opacity: 1;
  position: absolute;
  top: 144px;
  box-sizing: border-box;
  /* left: 30px; */
  border-radius: 5px;
}
.token-dialog-url-box,
.token-dialog-username-box,
.token-dialog-ssl-box,
.token-dialog-path-box,
.token-dialog-password-box {
  width: 322px;
  height: 23px;
  opacity: 1;
  font-size: 13px;
  line-height: 14px;
  opacity: 1;
  margin-left: 60px;
  margin-top: 9px;
  padding-left: 5px;
  border-radius: 5px;
}
.token-dialog-cancel {
  width: 60px;
  height: 26px;
  opacity: 1;
  position: absolute;
  left: 157px;
  top: 236px;
  font-size: 13px;
  line-height: 26px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.token-dialog-confirm {
  width: 64px;
  height: 30px;
  opacity: 1;
  position: absolute;
  left: 237px;
  top: 236px;
  font-size: 13px;
  line-height: 30px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
